import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a1a2ae14 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _367264f1 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _a4ab1da4 = () => interopDefault(import('../pages/history.vue' /* webpackChunkName: "pages/history" */))
const _6804692a = () => interopDefault(import('../pages/idlink.vue' /* webpackChunkName: "pages/idlink" */))
const _3d9c37fe = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _c1de3d0e = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _450aee50 = () => interopDefault(import('../pages/template.vue' /* webpackChunkName: "pages/template" */))
const _071b0228 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _a1a2ae14,
    name: "cart"
  }, {
    path: "/favorites",
    component: _367264f1,
    name: "favorites"
  }, {
    path: "/history",
    component: _a4ab1da4,
    name: "history"
  }, {
    path: "/idlink",
    component: _6804692a,
    name: "idlink"
  }, {
    path: "/search",
    component: _3d9c37fe,
    name: "search"
  }, {
    path: "/settings",
    component: _c1de3d0e,
    name: "settings"
  }, {
    path: "/template",
    component: _450aee50,
    name: "template"
  }, {
    path: "/",
    component: _071b0228,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
